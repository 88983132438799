<template>
    <div class="config-panel">
        <div class="panel-header">{{ activeData.label }}</div>

        <el-form v-if="Object.keys(activeData).length > 0" class="panel-form" ref="form" label-position="left">
            <!-- 组件id：field | 全部 -->
            <!-- <el-form-item class="form-item-column" label="组件ID">
                <span>{{ activeData.field }}</span>
            </el-form-item> -->

            <!-- 标题：label | 除文字说明 -->
            <el-form-item
                v-if="!['explain'].includes(activeData.type)" 
                class="form-item-column" label="标题"
            >
                <el-input v-model="activeData.label" />
            </el-form-item>

            <!-- 默认值：defaultValue | 除表单组、分割线、文字说明、图片示例 -->
            <!-- <template v-if="!['compsGroup', 'divider', 'explain', 'imgExample'].includes(activeData.type)">
                <el-form-item class="form-item-column" label="默认值">
                    <template v-if="['input', 'textarea'].includes(activeData.type)">
                        <el-input v-model="activeData.defaultValue" clearable />
                    </template>
                    <template v-else-if="['select'].includes(activeData.type)">
                        <el-select v-model="activeData.defaultValue" placeholder="默认值" :multiple="activeData.multiple">
                            <el-option v-for="item in activeData.options" :key="item.value" :label="item.label" :value="item.value" />
                        </el-select>
                    </template>
                </el-form-item>
            </template> -->

            <!-- 表单栅格：span | 除表单组、分割线-->
            <el-form-item
                v-if="!['compsGroup', 'divider'].includes(activeData.type) && activeData.span !== undefined"
                class="form-item-column" label="表单栅格"
            >
                <el-slider v-model="activeData.span" :max="24" :min="1" :marks="{ 12: '' }" />
            </el-form-item>

            <!-- 是否必填：required | 除表单组、分割线、文字说明、图片示例 -->
            <el-form-item v-if="!['compsGroup', 'divider', 'explain', 'imgExample'].includes(activeData.type) && activeData.required !== undefined" label="是否必填" >
                <el-switch v-model="activeData.required" />
            </el-form-item>

            <!-- 是否查询项：isQueryField | 除表单组、分割线、单图片上传、多图片上传、附件上传、文字说明、图片示例 -->
            <!-- <el-form-item
                v-if="!['compsGroup', 'divider', 'upload', 'miupload',
                        'fileload', 'explain', 'imgExample'].includes(activeData.type) 
                        && activeData.isQueryField !== undefined"
                class="form-item-row" label="是否查询项"
            >
                <el-switch v-model="activeData.isQueryField" />
            </el-form-item> -->

            <!-- 占位提示：placeholder | 单行文本、多行文本、下拉选择、附件上传、日期选择、地址选择、文字说明 -->
            <el-form-item
                v-if="
                    ['input', 'textarea', 'fileload', 'select', 'date', 'address', 'explain'].includes(activeData.type)
                    && activeData.placeholder !== undefined
                "
                class="form-item-column" label="占位提示"
            >
                <el-input v-model="activeData.placeholder" />
            </el-form-item>

            <!-- 最大长度：maxlength | 单行文本、多行文本 -->
            <el-form-item
                v-if="['input', 'textarea'].includes(activeData.type) && activeData.maxLength !== undefined"
                class="form-item-column" label="最多输入"
            >
                <el-input v-model="activeData.maxLength" placeholder="请输入字符长度">
                    <template slot="append"> 个字符 </template>
                </el-input>
            </el-form-item>

            <!-- 单行文本组件校验规则属性：rules | 单行文本 -->
            <el-form-item
                v-if="activeData.type === 'input' && activeData.rules && activeData.documentOptions"
                class="form-item-column" label="校验规则"
            >
                <el-select v-model="activeData.rules" placeholder="请选择" @change="rulesChange(activeData.rules)">
                    <el-option label="文本" value="text" />
                    <el-option label="数字" value="number" />
                    <el-option label="手机号" value="phone" />
                    <el-option label="证件号" value="document" />
                </el-select>
            </el-form-item>

            <!-- 证件类型选项：documentOptions | 单行文本 -->
            <el-form-item
                v-if="activeData.type === 'input' && documentOptionsShow"
                class="form-item-row" label="证件类型"
            >
                <el-checkbox-group v-model="documentOptionsRender" @change="documentOptionsChange">
                    <el-checkbox v-for="(item) in documentOptions" :key="item.value" :label="item.label"></el-checkbox>
                </el-checkbox-group>
            </el-form-item>

            <!-- 最小行数：minRows、最大行数：maxRows | 多行文本 -->
            <el-row v-if="['textarea'].includes(activeData.type)">
                <el-col :span="12">
                    <el-form-item v-if="activeData.minRows !== undefined" class="form-item-column" label="最小行数">
                        <el-input-number v-model="activeData.minRows" :max="activeData.maxRows" :min="1" placeholder="最小行数" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item v-if="activeData.maxRows !== undefined" class="form-item-column" label="最大行数">
                        <el-input-number v-model="activeData.maxRows" :min="activeData.minRows" placeholder="最大行数" />
                    </el-form-item>
                </el-col>
            </el-row>

            <!-- 选项：options | 下拉选择、单选框组、多选框组 -->
            <el-form-item
                v-if="['select', 'radio', 'checkbox'].includes(activeData.type)"
                class="form-item-column" label="选项"
            >
                <div v-for="(item, index) in activeData.options" :key="index" class="select-item">
                    <div class="select-line-icon">
                        <i class="el-icon-s-operation" />
                    </div>
                    <el-input v-model="item.label" placeholder="选项名" size="small" />
                    <div v-if="activeData.options.length > 1" class="close-btn select-line-icon" @click="reduceSelectItem(index)">
                        <i class="el-icon-remove-outline" />
                    </div>
                </div>
                <div style="margin-left: 20px">
                    <el-button style="padding-bottom: 0" icon="el-icon-circle-plus-outline" type="text" @click="addSelectItem">
                        添加选项
                    </el-button>

                    <el-button style="padding-bottom: 0" icon="el-icon-circle-plus-outline" type="text" @click="handleBatchEdit">
                        批量编辑
                    </el-button>
                </div>
            </el-form-item>

            <!-- 是否多选：multiple | 下拉选择 -->
            <el-form-item
                v-if="['select'].includes(activeData.type)"
                class="form-item-row" label="是否多选"
            >
                <el-switch v-model="activeData.multiple" @change="multipleChange" />
            </el-form-item>

            <!-- 配置关联：relatedComps | 下拉选择、单选框组、多选框组 -->
            <div v-if="['select', 'radio', 'checkbox'].includes(activeData.type)">
                <xk-button
                    style="margin-left: 0; margin-bottom: 18px; width: 100%"
                    type="primary" @click="relatedCompsDialogShow"
                >
                    {{ activeData.relatedComps.length > 0 ? '修改关联' : '配置关联' }}
                </xk-button>
            </div>

            <!-- 图片最大限制：fileSize | 单图片上传、多图片上传、附件上传 -->
            <el-form-item
                v-if="['upload', 'miupload', 'fileload'].includes(activeData.type)"
                class="form-item-column" 
                :label="activeData.type == 'fileload' ? '文件最大' : '图片最大'"
            >
                <el-input
                    v-model="activeData.fileSize"
                    :placeholder="activeData.type == 'fileload' ? '请输入文件大小' : '请输入图片大小'"
                    oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
                    @blur="function() {
                        if(activeData.fileSize == '') {
                            activeData.fileSize = 20
                        }else {
                            activeData.fileSize = parseInt(activeData.fileSize)
                        }
                    }"
                >
                    <template slot="append">{{ activeData.sizeUnit }}</template>
                </el-input>
            </el-form-item>

            <!-- 是否可裁剪：cuttable | 单图片上传 -->
            <!-- <el-form-item
                v-if="['upload'].includes(activeData.type)"
                class="form-item-row" label="是否可裁剪"
            >
                <el-switch v-model="activeData.cuttable" />
            </el-form-item> -->

            <!-- 最多上传数：limit | 多图片上传、附件上传 -->
            <el-form-item
                v-if="['miupload', 'fileload'].includes(activeData.type)"
                class="form-item-column" label="最多上传"
            >
                <el-input v-model="activeData.limit" placeholder="请输入最大上传数" oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
                    @blur="function() {
                        if(activeData.limit == ''){
                            activeData.limit = 2
                        }else {
                            activeData.limit = parseInt(activeData.limit)
                        }
                    }"
                >
                    <template slot="append">{{ activeData.type == 'fileload' ? '个文件' : '张图片' }}</template>
                </el-input>
            </el-form-item>

            <!-- 日期自动生成：autoGenerate | 日期选择 -->
            <!-- <el-form-item v-if="activeData.type === 'date'" class="form-item-row" label="自动生成">
                <el-switch v-model="activeData.autoGenerate"></el-switch>
            </el-form-item> -->

            <!-- 日期格式：format | 日期选择 -->
            <el-form-item v-if="activeData.type === 'date'" class="form-item-column" label="日期格式">
                <el-select v-model="activeData.format" placeholder="请选择" @change="dateFormatChange">
                    <el-option label="yyyy-MM-dd" value="yyyy-MM-dd" />
                    <el-option label="yyyy/MM/dd" value="yyyy/MM/dd" />
                    <el-option label="yyyy年MM月dd日" value="yyyy年MM月dd日" />
                </el-select>
            </el-form-item>

            <!-- 地址级别：addressLevel | 地址选择 -->
            <el-form-item
                v-if="['address'].includes(activeData.type)"
                class="form-item-column" label="地址级别"
            >
                <el-slider v-model="activeData.addressLevel" :max="4" :min="1" @change="addressLevelChange" />
            </el-form-item>

            <!-- 地址开始级别：startAddressLevel | 地址选择 -->
            <!-- <el-form-item
                v-if="['address'].includes(activeData.type)"
                class="form-item-column" label="开始级别"
            >
                <el-slider v-model="activeData.startAddressLevel" :max="activeData.addressLevel" :min="1" @change="addressLevelChange" />
            </el-form-item> -->

            <!-- 开始地址：startAddress | 开始地址 -->
            <el-form-item
                v-if="['address'].includes(activeData.type) && activeData.addressLevel > 1"
                :key="activeData.field + activeData.addressLevel"
                class="form-item-column" label="开始地址"
            >
                <el-cascader v-model="activeData.startAddress" style="width: 100%" :props="addressProps" clearable @change="startAddressChange" />
            </el-form-item>

            <!-- 字体大小：fontSize | 文字说明 -->
            <el-form-item
                v-if="['explain'].includes(activeData.type)" 
                class="form-item-column" label="字体大小"
            >
                <el-input v-model="activeData.fontSize" placeholder="请输入字体大小" />
            </el-form-item>

            <!-- 字体颜色：fontColor | 文字说明 -->
            <el-form-item
                v-if="['explain'].includes(activeData.type)"
                style="margin-bottom: 0;"
                class="form-item-row" label="字体颜色"
            >
                <el-color-picker v-model="activeData.fontColor" />
            </el-form-item>

            <!-- 字体粗细：fontWeight | 文字说明 -->
            <el-form-item
                v-if="['explain'].includes(activeData.type)"
                class="form-item-column" label="字体粗细"
            >
                <el-select v-model="activeData.fontWeight" placeholder="请选择">
                    <el-option label="更细" value="lighter" />
                    <el-option label="正常" value="normal" />
                    <el-option label="粗体" value="bold" />
                    <el-option label="更粗" value="bolder" />
                </el-select>
            </el-form-item>

            <!-- 字体样式：fontStyle | 文字说明 -->
            <el-form-item
                v-if="['explain'].includes(activeData.type)" 
                class="form-item-column" label="字体样式"
            >
                <el-select v-model="activeData.fontStyle" placeholder="请选择">
                    <el-option label="正常" value="normal" />
                    <el-option label="倾斜" value="oblique" />
                </el-select>
            </el-form-item>

            <!-- 文本修饰：textDecoration | 文字说明 -->
            <el-form-item
                v-if="['explain'].includes(activeData.type)"
                class="form-item-column" label="文本修饰"
            >
                <el-select v-model="activeData.textDecoration" placeholder="请选择">
                    <el-option label="标准" value="none" />
                    <el-option label="下划线" value="underline" />
                    <el-option label="上划线" value="overline" />
                    <el-option label="穿梭" value="line-through" />
                </el-select>
            </el-form-item>

            <!-- 文字位置：textAlign | 文字说明 -->
            <el-form-item
                v-if="['explain'].includes(activeData.type)"
                class="form-item-row" label="文字位置"
            >
                <el-radio-group v-model="activeData.textAlign">
                    <el-radio-button label="left">居左</el-radio-button>
                    <el-radio-button label="center">居中</el-radio-button>
                    <el-radio-button label="right">居右</el-radio-button>
                </el-radio-group>
            </el-form-item>

            <!-- 是否为超链接：hyperlinks | 文字说明 -->
            <el-form-item
                v-if="['explain'].includes(activeData.type) && !activeData.popUpWindow"
                class="form-item-row" label="是否为超链接"
            >
                <el-switch v-model="activeData.hyperlinks"></el-switch>
            </el-form-item>

            <!-- 链接地址：linkAddress | 文字说明 -->
            <el-form-item
                v-if="['explain'].includes(activeData.type) && activeData.hyperlinks"
                class="form-item-column" label="链接地址"
            >
                <el-input v-model="activeData.linkAddress"></el-input>
            </el-form-item>

            <!-- 是否弹窗：popUpWindow | 文字说明 -->
            <el-form-item
                v-if="['explain'].includes(activeData.type) && !activeData.hyperlinks"
                class="form-item-row" label="是否弹窗"
            >
                <el-switch v-model="activeData.popUpWindow"></el-switch>
            </el-form-item>

            <!-- 弹窗地址：popUpWindowAddress | 文字说明 -->
            <el-form-item
                v-if="['explain'].includes(activeData.type) && activeData.popUpWindow"
                class="form-item-column" label="弹窗地址"
            >
                <el-input v-model="activeData.popUpWindowAddress"></el-input>
            </el-form-item>

            <!-- 图片样例: placeholder | 图片示例 -->
            <el-form-item
                v-if="['imgExample'].includes(activeData.type)"
                class="form-item-column" label="图片样例"
            >
                <el-upload
                    style="flex: 1;"
                    class="avatar-uploader"
                    :action="action"
                    :headers="headers"
                    accept=".jpg,.jpeg,.png"
                    :show-file-list="false"
                    :on-success="handleImgExampleSuccess"
                >
                    <img v-if="activeData.placeholder" :src="activeData.placeholder" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </el-form-item>

            <!-- 字段说明：explain | 除表单组、分割线、文字说明 -->
            <el-form-item
                v-if="!['compsGroup', 'divider', 'explain'].includes(activeData.type)"
                class="form-item-column" label="字段说明"
            >
                <el-input v-model="activeData.explain" maxlength="100" clearable></el-input>
            </el-form-item>
        </el-form>

        <!-- 选项-批量编辑弹窗 -->
        <DialogWrapper :dialogObj="batchAdd" :close-on-click-modal="false" @handleClose="batchAdd.dialogVisible = false">
            <el-form ref="batchAddForm" :model="batchAdd" :rules="batchAddRules">
                <el-form-item label="选项" label-width="60px" prop="content">
                    <el-input type="textarea" v-model="batchAdd.content" :rows="10"></el-input>
                </el-form-item>
                <el-form-item style="text-align: center">
                    <el-button type="primary" @click="handleBatchEditDo">确定</el-button>
                </el-form-item>
            </el-form>
        </DialogWrapper>

        <!-- 配置关联弹窗 -->
        <DialogWrapper :dialogObj="relatedCompsDialogObj" @handleClose="relatedCompsDialogObj.dialogVisible = false">
            <div>
                <div class="related-comp-hint">*选择选项值，关联对应组件显示</div>
                <div v-for="(item, index) in relatedComps" :key="index" class="related-comp-item">
                    <el-select v-model="item.opt" placeholder="请选择选项值">
                        <el-option v-for="opt in activeData.options" :key="opt.value" :label="opt.label" :value="opt.value" />
                    </el-select>
                    <el-select v-model="item.field" placeholder="请选择关联组件" style="margin: 0 10px">
                        <el-option v-for="comp in canRelatedComps" :key="comp.field" :label="comp.label" :value="comp.field" />
                    </el-select>
                    <el-button type="text" style="font-size: 26px" icon="el-icon-remove" @click="relatedCompRemove(index)" />
                    <el-button type="text" icon="el-icon-circle-plus" style="font-size: 26px" @click="relatedCompAdd" />
                </div>
            </div>
            <div align="center">
                <xk-button type="primary" @click="relatedCompSave">保存</xk-button>
            </div>
        </DialogWrapper>
    </div>
</template>

<script>
import {
    // 业务组件
    DialogWrapper,
    // 学生档案模板相关
    stuInfoTemplateCompsDocumentOptions as documentOptions,
    stuInfoTemplateCompsValObjArr as compsValObjArr,
    stuInfoTemplateCompsChangeOptions as compsChangeOptions
} from 'common-local';
import { getToken } from '@/libs/auth';

export default {
    name: 'ConfigPanel',
    components: { DialogWrapper },
    props: [
        'activeData',
        'drawList'
    ],
    data() {
        return {
            loading: false,
            documentOptionsShow: false, // 证件类型可选项配置是否显示
            documentOptions, // 证件类型可选项全部数据
            documentOptionsRender: [], // 证件类型多选框组渲染数据
            // 选项-批量编辑相关
            batchAdd: {
                dialogVisible: false,
                title: '选项批量编辑',
                content: '',
                width: '500px',
            },
            batchAddRules: {
                content: [{
                    required: true,
                    message: '请输入选项',
                    trigger: 'change'
                }]
            },
            compsList: [], // 组件列表(表单组子组件的组件列表与正常组件的组件列表不一样)
            canRelatedComps: [], // 可被关联组件列表 
            // 配置关联弹窗相关
            relatedCompsDialogObj: {
                dialogVisible: false,
                title: '配置关联',
                width: '600px',
            },
            // 关联组件列表临时存储
            relatedComps: [{
                opt: '',
                field: ''
            }]
        }
    },
    filters: {},
    computed: {
        // 开始地址级联选择器Props配置
        addressProps() {
            let _this = this
            return {
                checkStrictly: true,
                expandTrigger: 'hover',
                lazy: true,
                lazyLoad(node, resolve) {
                    if(_this.activeData.addressLevel <= 1) {
                        const nodes = []
                        resolve(nodes)
                        return
                    }

                    const { level } = node
                    let parentCode = 0
                    if (level > 0) {
                        parentCode = node.data.code
                    }
                    console.log(
                        "[配置面板][addressProps]parentCode:", parentCode, 
                        "addressLevel:", _this.activeData.addressLevel,
                        "node.level:", node.level
                    )
                    _this._get(`/operate/common/getAreaList?parentCode=${parentCode}`).then((res) => {
                        // console.log("[配置面板][addressProps]地址列表：", res);
                        if(res.data.code == '200') {
                            const nodes = res.data.data.map((item) => {
                                // console.log(level)
                                return {
                                    code: item.areaCode,
                                    value: `${item.areaCode}-${item.areaName}`,
                                    label: item.areaName,
                                    // 判断叶子节点时要注意当level是0时返回数据的level是等于1的
                                    leaf: level + 1 >= _this.activeData.addressLevel - 1
                                }
                            })
                            resolve(nodes)
                        }
                    })
                }
            }
        },
        action() {
            return '/api/school/common/uploadFile'
        },
        headers() {
            return {
                Authorization: getToken()
            }
        }
    },
    watch: {
        "activeData.field": {
            handler(nVal, oVal) {
                this.activeCompChange()
            },
            deep: true,
            immediate: true
        },
    },
    created() {
    },
    mounted() {},
    beforeDestroy() {},
    methods: {
        // 简单深拷贝
        cloneDeep(data) {
            return JSON.parse(JSON.stringify(data))
        },
        // 选中组件改变
        activeCompChange() {
            this.loading = true

            // 获取组件列表，表单组子组件的组件列表与正常组件的组件列表不一样
            if(this.activeData.compsGroupId) {
                let compsList = this.cloneDeep(this.drawList)
                compsList = compsList.find(e => e.field == this.activeData.compsGroupId)?.subComps
                this.compsList = compsList ?? []
            }else {
                this.compsList = this.cloneDeep(this.drawList)
            }

            if(this.activeData.type == 'input') { // 单行文本
                if(this.activeData.rules == 'document') {
                    this.documentOptionsRender = this.activeData.documentOptions.map(e => e.label)
                    this.documentOptionsShow = true
                }else {
                    this.documentOptionsShow = false;
                    this.activeData.documentOptions = []
                }
            }

            this.loading = false;
        },
        // 校验规则-改变选中值
        rulesChange(rule) {
            if(rule == 'document') { // 校验规则是证件号
                this.activeData.documentOptions = this.cloneDeep(this.documentOptions)
                this.activeData.documentDefaultValue = this.activeData.documentOptions[0].value
                this.documentOptionsRender = this.documentOptions.map(i => i.label)
                this.documentOptionsShow = true;
                console.log("[rulesChange]this.activeData", this.activeData)
            }else { // 校验规则不是证件号
                this.activeData.documentOptions = []
            }
        },
        // 证件类型-修改选中项
        documentOptionsChange(data) {
            if(this.documentOptionsRender.length == 0) {
                this.$message.error('至少保留一项证件类型')
                this.documentOptionsRender = this.activeData.documentOptions.map(e => e.label)
            }else {
                this.activeData.documentOptions = this.documentOptions.filter(e => this.documentOptionsRender.includes(e.label))
            }
            // 如果取消的是当前的证件类型默认值，则将默认值改为第一项
            let isDocumentDefaultValue = this.activeData.documentOptions.findIndex(e => e.value == this.activeData.documentDefaultValue) == -1
            if(isDocumentDefaultValue) {
                this.$set(this.activeData, 'documentDefaultValue', this.activeData.documentOptions[0].value)
            }
            console.log("[documentOptionsChange]", data, this.documentOptionsRender, this.activeData.documentOptions, this.activeData.documentDefaultValue, isDocumentDefaultValue)
        },
        // 选项-删除选项
        reduceSelectItem(index) {
            let option = this.activeData.options[index]
            // 看删除的选项是否是默认值
            if(Object.keys(this.activeData).includes('defaultValue')) {
                if(compsValObjArr.includes(this.activeData.type)) {
                    if(this.activeData.type == 'select' && !this.activeData.multiple) {
                        if(option.value == this.activeData.defaultValue) {
                            this.activeData.defaultValue = ''
                        }
                    }else {
                        let optionsVals = this.activeData.options.filter(e => e.value != option.value).map(e => e.value)
                        this.activeData.defaultValue = this.activeData.defaultValue.filter(e => optionsVals.includes(e))
                    }
                }else if(option.value == this.activeData.defaultValue) {
                    this.activeData.defaultValue = ''
                }
            }
            console.log("[删除选项]this.activeData.defaultValue:", this.activeData.defaultValue)
            this.activeData.options.splice(index, 1)
            this.setOptionValue()
        },
        setOptionValue(item, val) {
            // item.value = isNumberStr(val) ? +val : val
            this.activeData.options.forEach((op, index) => {
                op.value = this.getValue(index + 1)
            })
        },
        getValue(index) {
            if (index < 10) {
                return '0' + index + '00'
            } else {
                return index + '00'
            }
        },
        // 选项-增加选项
        addSelectItem() {
            this.activeData.options.push({
                label: 'label',
                value: ''
            })
            this.setOptionValue()
        },
        // 选项-批量编辑弹窗
        handleBatchEdit() {
            let batchEditContent = ''
            if (this.activeData.options.length > 0) {
                this.activeData.options.forEach(op => {
                    if (batchEditContent !== '') {
                        batchEditContent = batchEditContent + '\n' + op.label
                    } else {
                        batchEditContent = op.label
                    }
                })
            }
            this.batchAdd.content = batchEditContent
            this.batchAdd.dialogVisible = true
        },
        // 选项-批量编辑弹窗确定按钮
        handleBatchEditDo() {
            this.$refs.batchAddForm.validate((valid) => {
                if (valid) {
                    let optionList = this.batchAdd.content.split('\n')
                    this.activeData.options = []
                    optionList.forEach(option => {
                        if (option) {
                            this.activeData.options.push({
                                label: option,
                                value: ''
                            })
                        }
                    })
                    this.setOptionValue()
                    this.batchAdd.dialogVisible = false
                } else {
                    return false;
                }
            })
        },
        // 是否多选-状态修改
        multipleChange(multiple) {
            // console.log("[multipleChange]multiple", multiple)
            this.$set(this.activeData, 'defaultValue', multiple ? [] : '')
            this.$forceUpdate()
        },
        // 获取被关联的全部组件
        getAllRelatedComps() {
            return this.compsList.filter(e => e.relatedComps?.length > 0)?.map(e => e.relatedComps)?.flat(Infinity)
        },
        // 获取除被自己关联的组件外其他被关联的组件
        getAllRelatedCompsExceptSelf() {
            return this.compsList.filter(e => e.field != this.activeData.field && e.relatedComps?.length > 0)?.map(e => e.relatedComps)?.flat(Infinity)
        },
        // 配置关联-显示弹窗
        relatedCompsDialogShow() {
            if(this.loading) {
                return this.$message.warning("数据处理中，请重试")
            }

            // 可被关联组件过滤掉自己
            let canRelatedComps = this.compsList.filter(e => e.field !== this.activeData.field)
            // 可被关联组件过滤掉已被其他组件关联的组件(被自己关联的要显示出来)
            let allRelatedComps = this.getAllRelatedCompsExceptSelf()?.map(e => e.field)
            console.log("[relatedCompsDialogShow]allRelatedComps:", allRelatedComps)
            canRelatedComps = canRelatedComps.filter(e => !allRelatedComps.includes(e.field))
            // 禁止链式关联
            if(allRelatedComps.includes(this.activeData.field)) {
                return this.$message.error("此组件已被其他组件关联");
            }
            let haveRelatedComps = this.compsList.filter(e => e.relatedComps?.length > 0)?.map(e => e.field)
            canRelatedComps = canRelatedComps.filter(e => !haveRelatedComps.includes(e.field))
            // 赋值给可关联组件列表
            this.canRelatedComps = canRelatedComps

            if(this.activeData.relatedComps.length > 0) {
                this.relatedComps = this.cloneDeep(this.activeData.relatedComps)
            }else {
                this.relatedComps = [{
                    opt: '',
                    field: ''
                }]
            }
            this.relatedCompsDialogObj.dialogVisible = true
        },
        // 配置关联-移除关联组件
        relatedCompRemove(i) {
            if (this.relatedComps.length > 1) {
                this.relatedComps.splice(i, 1)
            } else {
                this.relatedComps = [{
                    opt: '',
                    field: ''
                }]
            }
        },
        // 配置关联-添加关联组件
        relatedCompAdd() {
            this.relatedComps.push({
                opt: '',
                field: ''
            })
        },
        // 配置关联-保存关联组件
        relatedCompSave() {
            if(this.relatedComps.find(e => (!e.opt && e.field) || (e.opt && !e.field))) {
                this.$message.error('请选择选项值及对应关联组件')
                return
            }

            let relatedComps = this.cloneDeep(this.relatedComps)
            this.activeData.relatedComps = relatedComps.filter(e => e.field)
            console.log("[relatedCompSave]this.activeData.relatedComps:", this.activeData.relatedComps)
            this.relatedCompsDialogObj.dialogVisible = false
            this.$emit('relatedComps', this.activeData, this.activeData.defaultValue)
        },
        // 日期格式-选中项修改
        dateFormatChange(e) {
            if (this.activeData.defaultValue) {
                let value = this.activeData.defaultValue
                let year = value.substring(0, 4)
                let month = value.substring(5, 7)
                let day = value.substring(8, 10)
                // console.log(year, month, day)
                if (e == 'yyyy-MM-dd') {
                    value = year + '-' + month + '-' + day
                } else if (e == 'yyyy/MM/dd') {
                    value = year + '/' + month + '/' + day
                } else if (e == 'yyyy年MM月dd日') {
                    value = year + '年' + month + '月' + day + '日'
                }
                this.activeData.defaultValue = value
            }
            console.log("[dateFormatChange]", e, this.activeData)
        },
        // 地址级别-值改变
        addressLevelChange() {
            this.activeData.defaultValue = []
            this.activeData.startAddressLevel = 1
            this.activeData.startAddress = []
        },
        // 开始地址-值改变
        startAddressChange(val) {
            this.activeData.defaultValue = []
            this.activeData.startAddressLevel = val.length + 1
            console.log("[配置面板][startAddressChange]val:", val, "activeData.startAddressLevel:", this.activeData.startAddressLevel)
        },
        // 图片样例-上传成功
        handleImgExampleSuccess(res) {
            console.log("[配置面板][handleImgExampleSuccess]res:", res)
            if(res.code == '200') {
                this.activeData.placeholder = res.data.url
            }else {
                this.$message.error(res.msg);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.config-panel {
    height: 100%;
}

.panel-header {
    height: 40px;
    background: #F2F4F7;
    border-radius: 0px 8px 0px 0px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #737B87;
    line-height: 40px;
    padding-left: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.panel-form {
    padding: 28px 18px;
    box-sizing: border-box;
    height: calc(100% - 40px);
    overflow-y: auto;

    ::v-deep .el-checkbox-group {
        display: flex;
        flex-direction: column;
    }

    ::v-deep .el-input-number__decrease,
    ::v-deep .el-input-number__increase {
        height: 34px;
        line-height: 34px;
    }
}
.form-item-column {
    display: flex;
    flex-direction: column;
    justify-content: center;

    ::v-deep .el-form-item__label {
        width: 100% !important;
        text-align: left;
    }

    ::v-deep .el-form-item__content {
        margin-left: 0 !important;
    }

    ::v-deep .el-select {
        width: 100%;
    }
}
.form-item-row {
    display: flex;
    flex-direction: row;

    ::v-deep .el-form-item__label {
        width: auto !important;
    }

    ::v-deep .el-form-item__content {
        margin-left: 0 !important;
    }
}

// 选项
.select-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 4px;

    .select-line-icon {
        padding: 0 4px;
    }
}

// 配置关联
.related-comp-hint {
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
}
.related-comp-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}
</style>
